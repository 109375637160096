import React, { useEffect, useState } from 'react';
// Packages :
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// Pages :
import Pointage from './pages/Pointage';
import Login from './pages/Login';
import Historique from './pages/Historique';
import Notifications from './pages/Notifications';
import Profil from './pages/Profil';
import { Dashboard } from './pages/Dashboard';
// Componnents :
import { ProtectedRoutes } from './components/ProtectedRoutes';

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Login />} />

                <Route path='/dashboard' element={
                    <ProtectedRoutes>
                        <Dashboard />
                    </ProtectedRoutes>
                } />
                
                <Route path='/pointage' element={
                    <ProtectedRoutes>
                        <Pointage />
                    </ProtectedRoutes>
                } />

                <Route path='/historique' element={
                    <ProtectedRoutes>
                        <Historique />
                    </ProtectedRoutes>
                } />

                <Route path='/notifications' element={
                    <ProtectedRoutes>
                        <Notifications />
                    </ProtectedRoutes>
                } />

                <Route path='/profil' element={
                    <ProtectedRoutes>
                        <Profil />
                    </ProtectedRoutes>
                } />
            </Routes>
        </Router>
    );
}

export default App;