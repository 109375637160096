import React, { useEffect, useState } from 'react';
// Sidebar :
import Sidebar from '../components/Sidebar';
// fire base 
import { notificationCollection } from '../config/firebaseConfig';
import { getDocs, orderBy, query, where } from 'firebase/firestore';
// Icons :
import { BsBellSlash } from "react-icons/bs";

const Notifications = () => {
    const [notif, setNotif] = useState([]);

    const formatCustomDate = (timestamp) => {
        const dateObj = timestamp.toDate();
        return dateObj.toLocaleString('en-GB', {
            timeZone: 'UTC',
            hour: 'numeric',
            minute: 'numeric',
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        });
    };

    // ? ================================ Get All Notifications ================================
    useEffect(() => {
        const getNotifications = async () => {
            const user_id = localStorage.getItem('id');

            // Calculate the date 30 days ago
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

            const notifQuery = query(
                notificationCollection,
                where("agent_id", "==", user_id),
                where("date", "<=", new Date()), // Today
                where("date", ">=", thirtyDaysAgo), // 30 days ago
                orderBy("date", "desc") // Order by "date" in descending order
            );

            const notifSnapshot = await getDocs(notifQuery);
            const notifications = notifSnapshot.docs.map((doc) => doc.data());

            setNotif(notifications);
        };

        getNotifications()
    }, []);
    // ? =======================================================================================
    return (
        <div className="flex lg:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">

                <p className='text-2xl md:text-3xl lg:text-4xl font-bold mb-4'>Notifications</p>
                {
                    notif.length > 0
                        ? <>
                            {/* Notifications Cards */}
                            <div className='lg:mt-10 mt-6 flex flex-wrap justify-start items-start'>
                                {
                                    notif.map((notif, index) => (
                                        <div className='w-full lg:w-1/3 p-2' key={index}>
                                            <div className="bg-white p-3 rounded-lg shadow-md">
                                                <div className="flex items-start min-h-[100px]">
                                                    <div>
                                                        <p className='text-lg font-bold'>{notif.by}</p>
                                                        <p className='mt-2'>{notif.message}</p>
                                                    </div>
                                                </div>
                                                <div className='w-full flex justify-end'>
                                                    <p className='text-gray'>{formatCustomDate(notif.date)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                        : <>
                            <div className='flex flex-col items-center justify-center mt-5 md:mt-8 lg:mt-10'>
                                <BsBellSlash size={30} className='text-gray text-center mb-4' />
                                <p className='text-center text-gray'>Il n'y a aucune notification ! !</p>
                            </div>
                        </>
                }
            </main>
        </div>
    )
}

export default Notifications;