import React, { useEffect, useState } from "react";
// Packages :
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    Tooltip,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Toast :
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// And Design :
// import { Select } from "antd";
import Select from "react-select";

// Sidebar :
import Sidebar from "../components/Sidebar";
import { getAllAffaires } from "../config/affairesConfig";
import { addDoc, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import {
    affaireCollection,
    pointageCollection,
} from "../config/firebaseConfig";
import { DeletePointageTable } from "../components/DeletePointageTable";
// Icons :
import { AiOutlineDelete } from "react-icons/ai";
import { calcLength } from "framer-motion";

const Pointage = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [doubleClick, setDoubleClick] = useState(false);
    const [pointage, setPointage] = useState([]);
    const [affaires, setAffaires] = useState([]);
    // Tables :
    const [normalWorkTable, setNormalWorkTable] = useState([]);
    const [extraWorkTable, setExtraWorkTable] = useState([]);
    // Inputs :
    const [selectedAffaire, setSelectedAffaire] = useState("");
    const [selectedHours, setSelectedHours] = useState("");
    const [selectedExtraAffaire, setSelectedExtraAffaire] = useState("");
    const [selectedExtraHours, setSelectedExtraHours] = useState("");

    // this foor inputs select cuz 'react-select' doesnt take the value it takes the hole obj
    const [showedAffaire, setShowedAffaire] = useState();
    const [showedHours, setShowedHours] = useState("");
    const [showedExtraAffaire, setShowedExtraAffaire] = useState("");
    const [showedExtraHours, setShowedExtraHours] = useState("");

    // Prices :
    const [transportPrice, setTrnaportPrice] = useState("");
    const [deplacementPrice, setDeplacementPrice] = useState("");
    // Message :
    const [message, setMessage] = useState("");
    // Total Hours :
    const [totalNormalHours, setTotalNormalHours] = useState(0);
    const [totalExtraHours, setTotalExtraHours] = useState(0);
    // Checkboxs :
    const [transportCheck, setTransportCheck] = useState(false);
    const [deplacementCheck, setDeplacementCheck] = useState(false);
    const [holidayCheck, setHolidayCheck] = useState(false);
    const [recuperationsCheck, setRecuperationsCheck] = useState(false);
    const [congeCheck, setCongeCheck] = useState(false);
    const [maladeCheck, setMaladeCheck] = useState(false);
    const [absentCheck, setAbsentheck] = useState(false);

    const normalHours = [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
    ];
    const extraHours = [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
    ];

    // ? ====================================|| Handle Inputs ||===================================
    const handleSelectedAffaire = (e) => {
        setSelectedAffaire(e.value);
        setShowedAffaire(e);
    };
    const handleSelectedHours = (e) => {
        setSelectedHours(e.value);
        setShowedHours(e);
    };
    const handleTransportPrice = (e) => {
        setTrnaportPrice(e.target.value);
    };
    const handleDeplacementPrice = (e) => {
        setDeplacementPrice(e.target.value);
    };
    const handleMessage = (e) => {
        setMessage(e.target.value);
    };
    const handleSelectedExtraAffaire = (e) => {
        setSelectedExtraAffaire(e.value);
        setShowedExtraAffaire(e);
    };
    const handleSelectedExtraHours = (e) => {
        setSelectedExtraHours(e.value);
        setShowedExtraHours(e);
    };
    // ? ===========================================================================================

    // ? ====================================|| Search Input ||=====================================
    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    // ? ===========================================================================================

    // ? =====================================|| Checkboxs ||=======================================
    const handleCheckboxChangeHolidays = (n) => {
        if (n === 1) {
            setTransportCheck(!transportCheck);
        } else if (n === 2) {
            setDeplacementCheck(!deplacementCheck);
        } else if (n === 3) {
            setHolidayCheck(!holidayCheck);
        }
    };
    // ? ===========================================================================================

    // ? =======================================|| Toasts ||========================================
    const notifySuccess = () =>
        toast.success("Vous avez pointé avec succès", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    const notifyInfo = (message) =>
        toast.info(`${message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    const notifyError = (message) =>
        toast.error(`${message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    const notifyTimeoutError = () =>
        toast.error(
            "L'heure doit être comprise entre 16h00 et 23h59 pour pointer !",
            {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
            }
        );
    // ? ============================================================================================

    // ? ==================================|| Getting Affaires ||====================================
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getAllAffaires();
                setAffaires(result);
            } catch (error) { }
        };

        fetchData();
    }, []);
    // ? ============================================================================================

    // ? ================================|| Handling Pointage ||=====================================
    const timeHandler = async () => {
        const id = localStorage.getItem("id");

        try {
            // const currentHour = now.getHours();

            // * Check if the current time is between 16:00 and 23:59
            // if (currentHour < 4 || currentHour > 23) {
            //     return 'Timeout';
            // }

            const pointageQuery = query(
                pointageCollection,
                where("user_id", "==", id),
                where(
                    "date",
                    ">=",
                    new Date(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth(),
                        selectedDate.getDate()
                    )
                ),
                where(
                    "date",
                    "<",
                    new Date(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth(),
                        selectedDate.getDate() + 1
                    )
                )
            );

            const querySnapshot = await getDocs(pointageQuery);
            const pointage = querySnapshot.docs.map((doc) => doc.data());

            // * If there is an object inserted for the same day, return false
            if (pointage.length > 0) {
                return "Exist";
            } else {
                return "Send";
            }
        } catch (error) {
            return false;
        }
    };
    // ? ============================================================================================

    // ? =============================|| Inserting Pointage Function ||==============================
    const insertPointage = async () => {
        if (doubleClick) {
            // Button is already disabled, ignore the click
            return;
        }
        setDoubleClick(true);

        const user_id = localStorage.getItem("id");
        const group_id = localStorage.getItem("group_id");

        try {
            const pointageObj = {
                user_id: user_id,
                group_id: group_id,
                verified: false,
                verifiedBy: "",
                transport: transportCheck,
                transportPrice: transportPrice,
                deplacement: deplacementCheck,
                deplacementPrice: deplacementPrice,
                message: message,
                holiday: holidayCheck,
                normalWork: normalWorkTable,
                extraWork: extraWorkTable,
                totalNormalwork: totalNormalHours,
                totalExtrawork: totalExtraHours,
                recuperations: recuperationsCheck,
                conge: congeCheck,
                malade: maladeCheck,
                absent: absentCheck,
                totalHours: totalNormalHours + totalExtraHours,
                date: new Date(selectedDate),
            };

            const result = await timeHandler();

            if (
                result === "Send" &&
                (normalWorkTable.length > 0 ||
                    (deplacementCheck && isSunday(selectedDate)))
            ) {
                const point = await addDoc(pointageCollection, pointageObj);
                await setDoc(
                    doc(pointageCollection, point.id),
                    { pointage_id: point.id },
                    { merge: true }
                );
                notifySuccess();

                // Refresh Pointage Table :s
                unverifiedPointage();

                // ? Reset Data :
                // * Tables :
                setNormalWorkTable([]);
                setExtraWorkTable([]);
                // * Checkboxs :
                setDeplacementCheck(false);
                setTransportCheck(false);
                setHolidayCheck(false);
                // * Inputs :
                setSelectedExtraAffaire("");
                setSelectedExtraHours("");
                setSelectedAffaire("");
                setSelectedHours("");
                setMessage("");
                setDeplacementPrice("");
                setTrnaportPrice("");
                // * Total Hours :
                setTotalNormalHours(0);
                setTotalExtraHours(0);
            } else if (result === "Exist") {
                notifyError("Vous avez déjà pointé pour aujourd'hui !");
            } else {
                notifyError(
                    "Veuillez réessayer, n'oubliez pas de cliquer sur le bouton Ajouter pour ajouter votre travail"
                );
            }
        } catch (error) {
            notifyError("Il y a un problème, veuillez réessayer");
        } finally {
            // Enable the button after the operation is completed
            setDoubleClick(false);
        }
    };

    function isSunday(dateString) {
        const date = new Date(dateString);
        return date.getDay() === 0; // 0 represents Sunday
    }
    // ? ============================================================================================

    // ? ===================================|| Handle Tables ||======================================
    const handleNormalHoursAdd = () => {
        try {
            if (selectedAffaire !== "" && selectedHours !== "") {
                const newData = { codeAffaire: selectedAffaire, hours: selectedHours };
                setNormalWorkTable([...normalWorkTable, newData]);

                setTotalNormalHours(totalNormalHours + Number(selectedHours));

                // Reset inputs:
                setSelectedAffaire("");
                setSelectedHours("");
                setShowedAffaire("");
                setShowedHours("");
            }
            if (selectedAffaire === "" && selectedHours === "") {
                notifyInfo(
                    "Vous devez sélectionner une affaire et les heures de l'affaire"
                );
            }
            if (selectedAffaire === "" && selectedHours !== "") {
                notifyInfo("Vous devriez sélectionner une affaire");
            }
            if (selectedAffaire !== "" && selectedHours === "") {
                notifyInfo("Vous devez sélectionner les heures d'affaire");
            }
        } catch (error) {
            notifyError("Il y a un problème, veuillez réessayer");
        }
    };
    const handleExtraHoursAdd = () => {
        try {
            if (selectedExtraAffaire !== "" && selectedExtraHours !== "") {
                const newData = {
                    codeAffaire: selectedExtraAffaire,
                    hours: selectedExtraHours,
                };
                setExtraWorkTable([...extraWorkTable, newData]);

                setTotalExtraHours(totalExtraHours + Number(selectedExtraHours));

                // Reset inputs:
                setSelectedExtraAffaire("");
                setSelectedExtraHours("");
                setShowedExtraAffaire("");
                setShowedExtraHours("");
            }
            if (selectedExtraAffaire === "" && selectedExtraHours === "") {
                notifyInfo(
                    "Vous devez sélectionner une affaire et les heures de l'affaire"
                );
            }
            if (selectedExtraAffaire === "" && selectedExtraHours !== "") {
                notifyInfo("Vous devriez sélectionner une affaire");
            }
            if (selectedExtraAffaire !== "" && selectedExtraHours === "") {
                notifyInfo("Vous devez sélectionner les heures d'affaire");
            }
        } catch (error) {
            notifyError("Il y a un problème, veuillez réessayer");
        }
    };
    // ? ============================================================================================

    // ? ==================================|| Get Unverified Objects ||==============================
    const unverifiedPointage = async () => {
        const id = localStorage.getItem("id");
        const group_id = localStorage.getItem("group_id");
        const data = [];

        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to the beginning of the day

        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1); // Set to the next day

        const threeDays = new Date(today);
        threeDays.setDate(today.getDate() - 2); // Set to the next day

        try {
            const affairesSnapshot = await getDocs(affaireCollection);
            const affaires = affairesSnapshot.docs.map((doc) => doc.data());

            const pointageQuery = query(
                pointageCollection,
                where("user_id", "==", id),
                where("verified", "==", false),
                where("date", ">=", threeDays),
                where("date", "<", tomorrow)
            );

            const pointageSnapshot = await getDocs(pointageQuery);
            const pointage = pointageSnapshot.docs.map((doc) => doc.data());

            pointage.forEach((poi) => {
                const projectsNormalWork = poi.normalWork.map((work) => {
                    const affaire = affaires.find(
                        (aff) => aff.codeAffaire === work.codeAffaire
                    );
                    return {
                        affaireName: affaire ? affaire.nameAffaire : "-",
                        hours: work.hours,
                    };
                });

                const projectsExtraWork = poi.extraWork.map((work) => {
                    const affaire = affaires.find(
                        (aff) => aff.codeAffaire === work.codeAffaire
                    );
                    return {
                        affaireName: affaire ? affaire.nameAffaire : "-",
                        hours: work.hours,
                    };
                });

                let pointageSummaryMessage = "";

                projectsNormalWork.forEach((project) => {
                    pointageSummaryMessage += `${project.affaireName} / `;
                });

                projectsExtraWork.forEach((project) => {
                    pointageSummaryMessage += `${project.affaireName} / `;
                });

                const updatedPointageSummaryMessage = pointageSummaryMessage.slice(
                    0,
                    -2
                );

                let his = {
                    projectsNormalWork,
                    projectsExtraWork,
                    totalNormalwork: poi.totalNormalwork,
                    totalExtrawork: poi.totalExtrawork,
                    date: formatTimestamp(poi.date),
                    affaires: updatedPointageSummaryMessage,
                    transport: poi.transport,
                    transportPrice: poi.transportPrice,
                    deplacement: poi.deplacement,
                    deplacementPrice: poi.deplacementPrice,
                    message: poi.message,
                    holiday: poi.holiday,
                    id: poi.pointage_id,
                };

                data.push(his);
            });

            setPointage(data);
        } catch (error) {
            console.error("Error in timeHandler:", error);
            return false;
        }
    };
    function formatTimestamp(timestamp) {
        const dateObj = timestamp.toDate();
        const month = dateObj.getMonth() + 1;
        const day = dateObj.getDate();
        const year = dateObj.getFullYear();

        return `${day}/${month}/${year}`;
    }
    useEffect(() => {
        unverifiedPointage();
    }, []);
    // ? ============================================================================================

    // ? ==================================|| Refresh Pointage Table ||==============================
    const refreshPointageTable = () => {
        unverifiedPointage();
    };
    // ? ============================================================================================

    // ? =============================|| Handel Delete From Table ||=================================
    const handleDeleteNormalWork = (index, hours) => {
        const updatedNormalWorkTable = [...normalWorkTable];

        updatedNormalWorkTable.splice(index, 1);

        setNormalWorkTable(updatedNormalWorkTable);

        setTotalNormalHours(totalNormalHours - hours);
    };
    const handleDeleteExtraWork = (index, hours) => {
        const updatedExtraWorkTable = [...extraWorkTable];

        updatedExtraWorkTable.splice(index, 1);

        setExtraWorkTable(updatedExtraWorkTable);

        setTotalExtraHours(totalExtraHours - hours);
    };
    // ? ============================================================================================

    // ? =================================|| Handel Input Date ||====================================
    const [threeDays, setThreeDays] = useState(new Date());
    const [today, setToday] = useState(new Date());

    useEffect(() => {
        fetchCurrentDate();
    }, []);

    const fetchCurrentDate = () => {
        fetch('https://worldtimeapi.org/api/ip')
            .then(response => response.json())
            .then(data => {
                const currentDate = new Date(data.datetime);
                setToday(currentDate);
                setSelectedDate(currentDate); // Set selectedDate to current date from API
                const threeDaysAgo = new Date(currentDate);
                threeDaysAgo.setDate(currentDate.getDate() - 2);
                setThreeDays(threeDaysAgo);
            })
            .catch(error => {
                console.error('Error fetching current date:', error);
                // Fallback to using device time
                setToday(new Date());
                const threeDaysAgo = new Date();
                threeDaysAgo.setDate(new Date().getDate() - 2);
                setThreeDays(threeDaysAgo);
            });
    };

    // const currentDate = new Date();

    // const threeDays = new Date();
    // threeDays.setDate(currentDate.getDate() - 2);

    // const today = new Date();

    // const today = new Date();
    // const threeDaysAgo = new Date(today);
    // threeDaysAgo.setDate(today.getDate() - 2);

    // const isDateAllowed = (date) => {
    //     return date >= threeDaysAgo && date <= today;
    // };

    // const handleDateChange = (date) => {
    //     if (isDateAllowed(date)) {
    //         setSelectedDate(date);
    //     } else {
    //         // Handle invalid date (optional)
    //     }
    // };
    // ? ============================================================================================

    return (
        <div className="flex md:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                />

                <p className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
                    Pointage
                </p>

                {/* Date */}
                <div className="w-full bg-white rounded-lg p-4 mt-4">
                    <p>La Date</p>
                    <div className="border border-gray">
                        <DatePicker
                            className="px-4 py-2 outline-none"
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            minDate={threeDays}
                            maxDate={today}
                            dateFormat="MM/dd/yyyy"
                        />
                    </div>
                </div>

                {/* Normal Work */}
                <div className="bg-white rounded-lg p-4 mt-4">
                    <p className="lg:text-2xl text-lg font-bold mb-6">Travail normal</p>

                    {/* Project */}
                    <div className="mb-3">
                        <p className="mb-1">Selectionez le projet</p>
                        <Select
                            className="w-full rounded-md P-2"
                            size="large"
                            showSearch
                            placeholder="Selectionez le projet"
                            optionFilterProp="children"
                            // filterOption={filterOption}
                            onChange={handleSelectedAffaire}
                            value={showedAffaire}
                            options={affaires}
                        />
                    </div>

                    {/* Hours */}
                    <div className="mb-6">
                        <p className="mb-1">Sélectionnez les heures</p>
                        <Select
                            className="w-full rounded-md P-2"
                            size="large"
                            showSearch
                            placeholder="Sélectionnez les heures"
                            optionFilterProp="children"
                            // filterOption={filterOption}
                            onChange={handleSelectedHours}
                            value={showedHours}
                            options={normalHours}
                        />
                    </div>

                    {/* Button */}
                    <div className="w-full flex justify-end mt-4">
                        <button
                            className={`${totalNormalHours + Number(selectedHours) > 8
                                ? "bg-gray cursor-not-allowed"
                                : "bg-primary"
                                } px-6 py-2 rounded-md text-white`}
                            onClick={() => handleNormalHoursAdd()}
                            disabled={
                                totalNormalHours + Number(selectedHours) > 8 ? true : false
                            }
                        >
                            Ajouter
                        </button>
                    </div>
                </div>

                {/* Extra Work */}
                <div className="bg-white rounded-lg p-4 mt-4">
                    <p className="lg:text-2xl text-lg font-bold mb-6">
                        Heures supplémentaires
                    </p>

                    {/* Project */}
                    <div className="mb-3">
                        <p className="mb-1">Selectionez le projet</p>
                        <Select
                            className="w-full rounded-md P-2"
                            size="large"
                            showSearch
                            placeholder="Selectionez le projet"
                            optionFilterProp="children"
                            // filterOption={filterOption}
                            onChange={handleSelectedExtraAffaire}
                            value={showedExtraAffaire}
                            options={affaires}
                        />
                    </div>

                    {/* Hours */}
                    <div className="mb-6">
                        <p className="mb-1">Sélectionnez les heures</p>
                        <Select
                            className="w-full rounded-md P-2"
                            size="large"
                            showSearch
                            placeholder="Sélectionnez les heures"
                            optionFilterProp="children"
                            // filterOption={filterOption}
                            onChange={handleSelectedExtraHours}
                            value={showedExtraHours}
                            options={extraHours}
                        />
                    </div>

                    {/* Button */}
                    <div className="w-full flex justify-end mt-4">
                        <button
                            className="bg-primary px-6 py-2 rounded-md text-white"
                            onClick={() => handleExtraHoursAdd()}
                        >
                            Ajouter
                        </button>
                    </div>
                </div>

                {/* Deplacement & Trnasport & Holiday & Valider Button */}
                <div className="bg-white rounded-lg p-4 mt-4">
                    <FormGroup>
                        {/* Déplacement */}
                        <div className="flex flex-wrap justify-between items-center mb-2">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={deplacementCheck}
                                        onChange={() => handleCheckboxChangeHolidays(2)}
                                    />
                                }
                                label="Déplacement"
                                labelPlacement="end"
                            />
                            <input
                                type="number"
                                value={deplacementPrice}
                                onChange={handleDeplacementPrice}
                                disabled={!deplacementCheck}
                                placeholder="Entrez le prix"
                                className="w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2 rounded-md border border-gray"
                            />
                        </div>

                        {/* Trnasport */}
                        <div className="flex flex-wrap justify-between items-center mb-2">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={transportCheck}
                                        onChange={() => handleCheckboxChangeHolidays(1)}
                                    />
                                }
                                label="Transport"
                                labelPlacement="end"
                            />
                            <input
                                type="number"
                                value={transportPrice}
                                onChange={handleTransportPrice}
                                disabled={!transportCheck}
                                placeholder="Entrez le prix"
                                className="w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2 rounded-md border border-gray"
                            />
                        </div>

                        {/* Holiday */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={holidayCheck}
                                    onChange={() => handleCheckboxChangeHolidays(3)}
                                />
                            }
                            label="Jour férié"
                            labelPlacement="end"
                        />

                        {/* Message */}
                        <div className="mt-2">
                            <p className="font-normal mb-1">Message</p>
                            <input
                                type="text"
                                value={message}
                                onChange={handleMessage}
                                placeholder="Entrez votre message ..."
                                className="w-full px-2 py-2 rounded-md border border-gray mr-2"
                            />
                        </div>
                    </FormGroup>

                    {/* Valider Button */}
                    <div className="flex justify-end mt-4">
                        <button
                            className={`${doubleClick ? "bg-gray cursor-not-allowed" : "bg-primary"
                                } px-6 py-2 rounded-md text-white`}
                            onClick={insertPointage}
                            disabled={doubleClick}
                        >
                            Valider
                        </button>
                    </div>
                </div>

                {/* Normal Work Table */}
                {normalWorkTable && normalWorkTable.length > 0 ? (
                    <div className="mt-4">
                        <p className="lg:text-3xl text-xl font-extrabold mb-2">
                            Travail normal
                        </p>
                        <table className="w-full overflow-hidden rounded-t-lg">
                            <thead className="bg-primary text-white py-2">
                                <tr>
                                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                        Affaire
                                    </th>
                                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                        Heures
                                    </th>
                                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {normalWorkTable.map((data, index) => (
                                    <tr key={index}>
                                        <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                            {affaires.map((aff) => {
                                                if (data.codeAffaire === aff.value) {
                                                    return <p key={aff.value}>{aff.label}</p>;
                                                }
                                                return null;
                                            })}
                                        </td>
                                        <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                            {data.hours}
                                        </td>
                                        <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                            <div className="flex items-center justify-center cursor-pointer">
                                                <Tooltip
                                                    title="Supprimer"
                                                    onClick={() =>
                                                        handleDeleteNormalWork(index, data?.hours)
                                                    }
                                                >
                                                    <IconButton>
                                                        <AiOutlineDelete size={20} color="red" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : null}

                {/* Extra Work Table */}
                {extraWorkTable && extraWorkTable.length > 0 ? (
                    <div className="mt-4">
                        <p className="lg:text-3xl text-xl font-extrabold mb-2">
                            Heures supplémentaires
                        </p>
                        <table className="w-full overflow-hidden rounded-t-lg">
                            <thead className="bg-primary text-white py-2">
                                <tr>
                                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                        Affaire
                                    </th>
                                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                        Heures supplémentaires
                                    </th>
                                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {extraWorkTable.map((data, index) => (
                                    <tr key={index}>
                                        <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                            {affaires.map((aff) => {
                                                if (data.codeAffaire === aff.value) {
                                                    return <p key={aff.value}>{aff.label}</p>;
                                                }
                                                return null;
                                            })}
                                        </td>
                                        <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                            {data.hours}
                                        </td>
                                        <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                            <div className="flex items-center justify-center cursor-pointer">
                                                <Tooltip
                                                    title="Supprimer"
                                                    onClick={() =>
                                                        handleDeleteExtraWork(index, data?.hours)
                                                    }
                                                >
                                                    <IconButton>
                                                        <AiOutlineDelete size={20} color="red" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : null}

                <DeletePointageTable
                    pointage={pointage}
                    onDelete={() => refreshPointageTable()}
                />
            </main>
        </div>
    );
};

export default Pointage;
