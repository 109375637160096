import React, { useEffect, useState } from "react";
// Firebase :
import { deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import {
  affaireCollection,
  pointageCollection,
} from "../config/firebaseConfig";
// Icons :
import { FiTrash } from "react-icons/fi";
import { IoIosCheckmark } from "react-icons/io";
// Material UI :
import { Tooltip, Box, Modal, Button } from "@mui/material";
// Toast :
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DeletePointageModal } from "./PointageComponents/DeletePointageModal";

export const DeletePointageTable = ({ pointage, onDelete }) => {
  // =============================|| Modal Settings ||=====================================
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [width, setWidth] = useState("50%");
  const [padding, setPadding] = useState(3);
  const currentWidth = window.innerWidth;

  useEffect(() => {
    if (currentWidth <= 670) {
      setWidth("80%");
      setPadding(2);
    }
  }, [currentWidth]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: padding,
    borderRadius: 3,
  };
  // ======================================================================================

  // =============================|| Toast ||=====================================
  const notifySuccess = () =>
    toast.success("Pointage supprimé avec succès.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  const notifyError = () =>
    toast.error("Il y a une erreur, veuillez réessayer", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  // ======================================================================================

  return (
    <>
      {pointage.length > 0 && (
        <div className="bg-white p-4 rounded-lg shadow-md mt-4 overflow-y-scroll">
          <table className="w-full overflow-hidden rounded-t-lg">
            <thead className="bg-primary text-white py-2">
              <tr>
                <th className="py-3 px-3 text-sm lg:text-base border border-white">
                  Date
                </th>
                <th className="py-3 px-3 text-sm lg:text-base border border-white">
                  Affaires
                </th>
                <th className="py-3 px-3 text-sm lg:text-base border border-white">
                  Normal Heures
                </th>
                <th className="py-3 px-3 text-sm lg:text-base border border-white">
                  Heures <br />
                  supplémentaire
                </th>
                <th className="py-3 px-3 text-sm lg:text-base border border-white">
                  Message
                </th>
                <th className="py-3 px-3 text-sm lg:text-base border border-white">
                  Transport
                </th>
                <th className="py-3 px-3 text-sm lg:text-base border border-white">
                  Déplacement
                </th>
                <th className="py-3 px-3 text-sm lg:text-base border border-white">
                  Jour férié
                </th>
                <th className="py-3 px-3 text-sm lg:text-base border border-white">
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {pointage.map((data, index) => (
                <tr key={index}>
                  <td
                    className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                  >
                    {data?.date}
                  </td>
                  <td
                    className={`${
                      data?.affaires === "-" ? "bg-light border-x-0" : ""
                    } border border-gray py-2 px-3 text-sm lg:text-base`}
                  >
                    {data?.affaires}
                  </td>
                  <td
                    className={`${
                      data?.totalNormalwork === "-" ? "bg-light border-x-0" : ""
                    } border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                  >
                    {data?.totalNormalwork}
                  </td>
                  <td
                    className={`${
                      data?.totalExtrawork === "-" ? "bg-light border-x-0" : ""
                    } border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                  >
                    {data?.totalExtrawork}
                  </td>
                  <td
                    className={`${
                      data?.message === "" ? "bg-light border-x-0" : ""
                    } 
                                        border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                  >
                    {data?.message ? data?.message : "-"}
                  </td>
                  <td
                    className={`${
                      !data?.transport ? "bg-light border-x-0" : ""
                    } 
                                        border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                  >
                    {data?.transport && data?.transportPrice !== ""
                      ? data?.transportPrice + " DHs"
                      : null}
                    <p className="w-full flex justify-center">
                      {data?.transport && data?.transportPrice === "" ? (
                        <IoIosCheckmark color="green" size={34} />
                      ) : null}
                    </p>
                  </td>
                  <td
                    className={`${
                      !data?.deplacement ? "bg-light border-x-0" : ""
                    } 
                                        border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                  >
                    {data?.deplacement && data?.deplacementPrice !== ""
                      ? data?.deplacementPrice + " DHs"
                      : null}
                    <p className="w-full flex justify-center">
                      {data?.deplacement && data?.deplacementPrice === "" ? (
                        <IoIosCheckmark color="green" size={34} />
                      ) : null}
                    </p>
                  </td>
                  <td
                    className={`${
                      data?.holiday ? "" : "bg-light border-x-0"
                    } border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                  >
                    <p className="w-full flex justify-center">
                      {data?.holiday ? (
                        <IoIosCheckmark color="green" size={34} />
                      ) : null}
                    </p>
                  </td>
                  <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                    <DeletePointageModal
                      id={data?.id}
                      date={data?.date}
                      onDelete={() => onDelete()}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        
        </div>
      )}
    </>
  );
};
