import React, { useEffect, useState } from "react";
// Components :
import Sidebar from "../components/Sidebar";
// And Design :
import { Select } from "antd";
// Toast :
import { ToastContainer } from "react-toastify";
import { getDocs, query, where } from "firebase/firestore";
import { pointageCollection, userCollection } from "../config/firebaseConfig";
// Icons :
import { BsDatabaseSlash } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";
import { FormControlLabel, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const Dashboard = () => {
  const [data, setData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState([]);
  const [details, setDetails] = useState(false);

  // ? ===============================|| data functions ||===============================
  function getDateForPointage(selectedMonth) {
    // Parse the selected month string, e.g., "2022-02"
    const [year, month] = selectedMonth.split("-");

    // Set the date to the first day of the selected month
    const startDate = new Date(parseInt(year), parseInt(month) - 1, 1);

    // Set the date to the last day of the selected month
    const endDate = new Date(parseInt(year), parseInt(month), 0);

    // Adjust to local time zone (GMT+1)
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    let date = {
      start: startDate,
      end: endDate,
    };

    return date;
  }

  const getdata = async (month, group) => {
    try {
      const user_id = localStorage.getItem("id");
      const agent = await fetchAgents(user_id);
      const pointage = await fetchPointage(month, agent);
      const processedData = processData(agent, pointage, month);
      const sortedData = sortData(processedData);
      ////console.log("sortedData :", sortedData);
      setData(sortedData); // Assuming setData is a function to set the data in your application state
      getDays(month);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const fetchAgents = async (user_id) => {
    try {
      const agentsQuery = query(
        userCollection,
        where("user_id", "==", user_id)
        // where("role", "==", "Agent")
      );

      const querySnapshot = await getDocs(agentsQuery);
      const agents = querySnapshot.docs.map((doc) => doc.data());

      return agents;
    } catch (error) {}
  };

  const fetchPointage = async (month, agent) => {
    const date = getDateForPointage(month);

    try {
      let pointage = [];

      // Use Promise.all to wait for all async operations to complete
      await Promise.all(
        agent.map(async (ag) => {
          const pointageQuery = query(
            pointageCollection,
            where("user_id", "==", ag.user_id),
            where("date", ">=", new Date(date.start)),
            where("date", "<=", new Date(date.end))
          );

          const querySnapshot = await getDocs(pointageQuery);
          const result = querySnapshot.docs.map((doc) => doc.data());

          result.map((res) => pointage.push(res));
        })
      );

      return pointage;
    } catch (error) {
      console.error("Error occurred:", error);
      throw error;
    }
  };

  const processData = (agent, pointage, month) => {
    let data = [];

    ////console.log("pointage :", pointage);

    agent.forEach((ag) => {
      let days = [];

      pointage.forEach((poi) => {
        const poiTimestamp =
          poi.date.seconds * 1000 + Math.round(poi.date.nanoseconds / 1e6);
        const poiDate = new Date(poiTimestamp);
        const today = poiDate.getDate();

        if (ag.user_id === poi.user_id) {
          let value;
          if (poi.normalWork.length > 0 && poi.verified === true) {
            value = 1;
          } else if (poi.normalWork.length > 0 && poi.verified === false) {
            value = 2;
          } else if (poi.totalHours === 0 && poi.recuperations === true) {
            value = 4;
          } else if (poi.totalHours === 0 && poi.conge === true) {
            value = 5;
          } else if (poi.totalHours === 0 && poi.absent === true) {
            value = 6;
          } else if (poi.totalHours === 0 && poi.malade === true) {
            value = 7;
          } else if (poi.totalHours === 0 && poi.feteAid === true) {
            value = 10;
          } else if (poi.totalHours === 0 && poi.holiday === true) {
            value = 11;
          }
          if (value !== undefined) {
            let affArray = [];

            if (poi.normalWork.length !== 0) {
              poi.normalWork.forEach((el) => {
                affArray.push(el.codeAffaire);
              });
            }
            if (poi.extraWork.length !== 0) {
              poi.extraWork.forEach((el) => {
                affArray.push(el.codeAffaire);
              });
            }

            // Remove duplicates from the array
            affArray = [...new Set(affArray)];

            // Concatenate unique codeAffaire values separated by '/'
            let codeAff = affArray.join(" / ");

            let m = {
              [today]: value,
              totalHours: poi.totalHours,
              codeAff: codeAff === "" ? "-" : codeAff,
            };
            days.push(m);
          }
        }
      });

      addMissingDays(days, month);

      days.sort(
        (a, b) => parseInt(Object.keys(a)[0]) - parseInt(Object.keys(b)[0])
      );

      let obj = {
        name: ag.fullName,
        days: days,
      };

      data.push(obj);
    });

    return data;
  };

  const addMissingDays = (days, month) => {
    const currentDate = new Date();
    const thisYear = currentDate.getFullYear();
    const thisMonth = currentDate.getMonth() + 1;

    const sentDate = new Date(month);
    const sentYear = sentDate.getFullYear();
    const sentMonth = sentDate.getMonth() + 1;

    const daysInMonth = new Date(sentYear, sentMonth, 0).getDate();

    if (thisYear === sentYear && thisMonth === sentMonth) {
      const today = currentDate.getDate();
      for (let i = 1; i <= daysInMonth; i++) {
        if (!days.some((day) => parseInt(Object.keys(day)[0]) === i)) {
          let m = {};
          let dt = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            i
          );

          if (isSunday(dt) && i <= today) {
            m = { [i]: 3, totalHours: 0, codeAff: "-" };
          } else {
            m = {
              [i]: i < today ? 8 : 9,
              totalHours: 0,
              codeAff: "-",
            };
          }
          days.push(m);
        }
      }
    } else if (thisYear >= sentYear && thisMonth >= sentMonth) {
      for (let i = 1; i <= daysInMonth; i++) {
        if (!days.some((day) => parseInt(Object.keys(day)[0]) === i)) {
          let m = {};
          let dt = new Date(sentDate.getFullYear(), sentDate.getMonth(), i);

          if (isSunday(dt)) {
            m = { [i]: 3, totalHours: 0, codeAff: "-" };
          } else {
            m = {
              [i]: 8,
              totalHours: 0,
              codeAff: "-",
            };
          }
          days.push(m);
        }
      }
    } else {
      for (let i = 1; i <= daysInMonth; i++) {
        if (!days.some((day) => parseInt(Object.keys(day)[0]) === i)) {
          let m = {
            [i]: 9,
          };
          days.push(m);
        }
      }
    }
  };

  const isSunday = (dt) => {
    const date = new Date(dt);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0;
  };

  const sortData = (data) => {
    return data.sort((a, b) => {
      const countA = a.days.filter((day) =>
        [1, 2].includes(Object.values(day)[0])
      ).length;
      const countB = b.days.filter((day) =>
        [1, 2].includes(Object.values(day)[0])
      ).length;
      return countB - countA;
    });
  };

  const getDays = (date) => {
    // Create a Date object for the first day of the specified month and year
    const firstDayOfMonth = new Date(date);

    // Get the index of the first day of the month in the dayNames array
    const firstDayIndex = firstDayOfMonth.getDay();

    // Define the array of day names
    const dayNames = ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"];

    // Create an array to store the 16 day names
    const result = [];

    // Loop to populate the result array with the next 16 days
    for (let i = 0; i < 7; i++) {
      // Calculate the index of the current day name using modulo operation
      const index = (firstDayIndex + i - 1) % 7;
      // Push the corresponding day name to the result array
      result.push(dayNames[index]);
    }

    setDays(result);
    // return result;
  };

  const colorFunc = (num) => {
    try {
      switch (num) {
        case 1:
          return "bg-green";

        case 2:
          return "bg-yellow";

        case 3:
          return "bg-gray";

        case 4:
          return "bg-lightBlue";

        case 5:
          return "bg-purple";

        case 6:
          return "bg-softRed";

        case 7:
          return "bg-blue";

        case 8:
          return "bg-[red]";

        case 9:
          return "bg-white";

        case 10:
          return "bg-pink";

        case 11:
          return "bg-orange";

        default:
          return "  bg-white invisible";
      }
    } catch (error) {
      console.error("Error in colorFunc:", error);
      return "defaultClass";
    }
  };

  // ? ==============================================================================

  // ? ===============================|| Inputs Handlers ||===============================
  const handleSelectedGroup = (val) => {
    setSelectedGroup(val);
  };
  const handleSelectedMonth = (event) => {
    setSelectedMonth(event.target.value);
  };
  const pointageFunction = async (month) => {
    setLoading(true);
    await getdata(month);
    await setSelectedMonth(month);
    setLoading(false);
  };
  // ? ==============================================================================

  // ? ====================================|| Search Input ||========================
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  // ? ==============================================================================

  // ? ==============================================================================
  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();
      const thisMonth = currentDate.toISOString().slice(0, 7);

      setLoading(true);
      await setSelectedMonth(thisMonth);
      await getdata(thisMonth);
      setLoading(false);
    };

    fetchData();
  }, []);
  // ? ==============================================================================

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const changeDetails = () => {
    setDetails(!details);
  };

  return (
    <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
      <Sidebar />
      <main className="flex-1 py-4 pr-4 h-screen overflow-y-scroll">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="colored"
        />

        {/* Title */}
        <div className="flex justify-between items-center">
          <p className="text-3xl lg:text-4xl font-bold m-1">Aperçu</p>
        </div>

        <div className="flex flex-wrap justify-between items-end">
          <div className="w-full lg:w-2/3 mt-10 lg:flex justify-between items-center">
            {/* Select Date */}
            <div className="w-full lg:w-1/3 mr-2">
              <p className="font-medium">Mois</p>
              <input
                type="month"
                className="w-full border border-gray rounded-md py-2 px-4"
                value={selectedMonth}
                onChange={(e) => pointageFunction(e.target.value)}
              />
            </div>
            <div className=" flex items-center w-full lg:w-1/3 mr-20 mt-5">
              <p className="font-medium m-2 p-2">Détails</p>
              <Switch {...label} onClick={changeDetails} />
            </div>
          </div>
        </div>

        {/* Tables */}
        <div className="mt-4">
          <div className="mt-2 flex flex-col flex-wrap lg:flex-row lg:justify-between lg:items-center mb-2">
            <div className="mb-2 lg:mb-0 mr-8 flex text-center items-center mt-2">
              <div className="w-6 h-6 bg-green rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">
                Pointé et vérifié
              </span>
            </div>

            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-yellow rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">
                Pointé et non vérifié
              </span>
            </div>

            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 rounded-full border border-black bg-[red]"></div>
              <span className="text-sm text-black ml-2 pt-1">Non Pointé</span>
            </div>

            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-purple rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">Congé</span>
            </div>

            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-blue rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">Malade</span>
            </div>

            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-softRed rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">Absent(e)</span>
            </div>

            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-pink rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">fête aïd</span>
            </div>
            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-orange rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">Jour férié</span>
            </div>
            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-gray rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">
                Il n'a pas travaillé dimanche
              </span>
            </div>
            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
              <div className="w-6 h-6 bg-lightBlue rounded-full border border-black"></div>
              <span className="text-sm text-black ml-2 pt-1">
                Il n'a pas travaillé dimanche (Avec deplacement)
              </span>
            </div>
          </div>

          {/* Agents Table */}
          <div className="overflow-x-scroll">
            {details ? (
              <table className="w-full overflow-hidden rounded-t-lg">
                <thead className="bg-primary text-white py-2">
                  <tr>
                    <td
                      className="py-3 text-center text-sm lg:text-base border border-white"
                      colSpan={14}
                    >
                      Jours du mois
                    </td>
                  </tr>
                  <tr>
                    {days.map((d, i) => (
                      <td
                        colSpan={2}
                        className="py-3 text-center text-sm lg:text-base border border-white"
                        key={i}
                      >
                        {d}
                      </td>
                    ))}
                  </tr>
                </thead>

                {loading ? (
                  <tbody className="bg-white">
                    <tr>
                      <td colSpan={14}>
                        <Skeleton
                          animation="wave"
                          width="98%"
                          height={50}
                          className="mx-auto"
                        />
                        <Skeleton
                          animation="wave"
                          width="98%"
                          height={50}
                          className="mx-auto"
                        />
                        <Skeleton
                          animation="wave"
                          width="98%"
                          height={50}
                          className="mx-auto"
                        />
                        <Skeleton
                          animation="wave"
                          width="98%"
                          height={50}
                          className="mx-auto"
                        />
                        <Skeleton
                          animation="wave"
                          width="98%"
                          height={50}
                          className="mx-auto"
                        />
                        <Skeleton
                          animation="wave"
                          width="98%"
                          height={50}
                          className="mx-auto"
                        />
                      </td>
                    </tr>
                  </tbody>
                ) : data?.length > 0 ? (
                  data?.map((poi, i) => (
                    <tbody key={i} className="mt-2 bg-white">
                      <tr className="mt-2">
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[0]?.["1"]
                          )}`}
                        >
                          1
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[0]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[1]?.["2"]
                          )}`}
                        >
                          2
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[1]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[2]?.["3"]
                          )}`}
                        >
                          3
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[2]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[3]?.["4"]
                          )}`}
                        >
                          4
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[3]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[4]?.["5"]
                          )}`}
                        >
                          5
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[4]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[5]?.["6"]
                          )}`}
                        >
                          6
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[5]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[6]?.["7"]
                          )}`}
                        >
                          7
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[6]?.totalHours} h
                        </td>
                      </tr>
                      <tr className="mt-2">
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[0]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[1]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[2]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[3]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[4]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[5]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[6]?.codeAff}
                        </td>
                      </tr>

                      <tr className="mt-2">
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[7]?.["8"]
                          )}`}
                        >
                          8
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[7]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[8]?.["9"]
                          )}`}
                        >
                          9
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[8]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[9]?.["10"]
                          )}`}
                        >
                          10
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[9]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[10]?.["11"]
                          )}`}
                        >
                          11
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[10]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[11]?.["12"]
                          )}`}
                        >
                          12
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[11]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[12]?.["13"]
                          )}`}
                        >
                          13
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[12]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[13]?.["14"]
                          )}`}
                        >
                          14
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[13]?.totalHours} h
                        </td>
                      </tr>
                      <tr className="mt-2">
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[7]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[8]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[9]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[10]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[11]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[12]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[13]?.codeAff}
                        </td>
                      </tr>

                      <tr className="mt-2">
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[14]?.["15"]
                          )}`}
                        >
                          15
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[14]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[15]?.["16"]
                          )}`}
                        >
                          16
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[15]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[16]?.["17"]
                          )}`}
                        >
                          17
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[16]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[17]?.["18"]
                          )}`}
                        >
                          18
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[17]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[18]?.["19"]
                          )}`}
                        >
                          19
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[18]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[19]?.["20"]
                          )}`}
                        >
                          20
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[19]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[20]?.["21"]
                          )}`}
                        >
                          21
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[20]?.totalHours} h
                        </td>
                      </tr>
                      <tr className="mt-2">
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[14]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[15]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[16]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[17]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[18]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[19]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[20]?.codeAff}
                        </td>
                      </tr>

                      <tr className="mt-2">
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[21]?.["22"]
                          )}`}
                        >
                          22
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[21]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[22]?.["23"]
                          )}`}
                        >
                          23
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[22]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[23]?.["24"]
                          )}`}
                        >
                          24
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[23]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[24]?.["25"]
                          )}`}
                        >
                          25
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[24]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[25]?.["26"]
                          )}`}
                        >
                          26
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[25]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[26]?.["27"]
                          )}`}
                        >
                          27
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[26]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[27]?.["28"]
                          )}`}
                        >
                          28
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[27]?.totalHours} h
                        </td>
                      </tr>
                      <tr className="mt-2">
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[21]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[22]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[23]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[24]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[25]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[26]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[27]?.codeAff}
                        </td>
                      </tr>

                      <tr className="mt-2">
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[28]?.["29"]
                          )}`}
                        >
                          29
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[28]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[29]?.["30"]
                          )}`}
                        >
                          30
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold`}
                        >
                          {poi.days[29]?.totalHours} h
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border  border-r-gray border-b-gray lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[30]?.["31"]
                          )}`}
                        >
                          31
                        </td>
                        <td
                          className={` min-w-[30px] text-sm lg:text-base text-center border  border-b-gray lg:min-w-0 lg:py-2 bg-white font-semibold ${colorFunc(
                            poi.days[30]?.["31"]
                          )}`}
                        >
                          {poi.days[30]?.totalHours} h
                        </td>
                      </tr>
                      <tr className="mt-2">
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[28]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white`}
                        >
                          {poi.days[29]?.codeAff}
                        </td>
                        <td
                          colSpan={2}
                          className={`min-w-[30px] text-sm lg:text-base text-center border  lg:min-w-0 lg:py-2 bg-white ${colorFunc(
                            poi.days[30]?.["31"]
                          )}`}
                        >
                          {poi.days[30]?.codeAff}
                        </td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  <tbody className="bg-white">
                    <tr>
                      <td colSpan="15" className="text-center py-4 text-gray">
                        <div className="flex flex-col justify-center items-center mt-4">
                          <BsDatabaseSlash size={30} className="mb-2" />
                          <p>Pas de données disponibles.</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <table className="w-full overflow-hidden rounded-t-lg">
                <thead className="bg-primary text-white py-2">
                  <tr>
                    <td
                      className="py-3 text-center text-sm lg:text-base border border-white"
                      colSpan={8}
                    >
                      Jours du mois
                    </td>
                  </tr>
                  <tr>
                    {days.map((d, i) => (
                      <td
                        className="py-3 text-center text-sm lg:text-base border border-white"
                        key={i}
                      >
                        {d}
                      </td>
                    ))}
                  </tr>
                </thead>

                {loading ? (
                  <tbody className="bg-white">
                    <tr>
                      <td colSpan={8}>
                        <Skeleton
                          animation="wave"
                          width="98%"
                          height={50}
                          className="mx-auto"
                        />
                        <Skeleton
                          animation="wave"
                          width="98%"
                          height={50}
                          className="mx-auto"
                        />
                        <Skeleton
                          animation="wave"
                          width="98%"
                          height={50}
                          className="mx-auto"
                        />
                        <Skeleton
                          animation="wave"
                          width="98%"
                          height={50}
                          className="mx-auto"
                        />
                        <Skeleton
                          animation="wave"
                          width="98%"
                          height={50}
                          className="mx-auto"
                        />
                        <Skeleton
                          animation="wave"
                          width="98%"
                          height={50}
                          className="mx-auto"
                        />
                      </td>
                    </tr>
                  </tbody>
                ) : data?.length > 0 ? (
                  data?.map((poi, i) => (
                    <tbody key={i} className="mt-2 bg-white">
                      <tr className="mt-2">
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[0]?.["1"]
                          )}`}
                        >
                          1
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2 ${colorFunc(
                            poi.days[1]?.["2"]
                          )}`}
                        >
                          2
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[2]?.["3"]
                          )}`}
                        >
                          3
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[3]?.["4"]
                          )}`}
                        >
                          4
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[4]?.["5"]
                          )}`}
                        >
                          5
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[5]?.["6"]
                          )}`}
                        >
                          6
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[6]?.["7"]
                          )}`}
                        >
                          7
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[7]?.["8"]
                          )}`}
                        >
                          8
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[8]?.["9"]
                          )}`}
                        >
                          9
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[9]?.["10"]
                          )}`}
                        >
                          10
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[10]?.["11"]
                          )}`}
                        >
                          11
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[11]?.["12"]
                          )}`}
                        >
                          12
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[12]?.["13"]
                          )}`}
                        >
                          13
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[13]?.["14"]
                          )}`}
                        >
                          14
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[14]?.["15"]
                          )}`}
                        >
                          15
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[15]?.["16"]
                          )}`}
                        >
                          16
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[16]?.["17"]
                          )}`}
                        >
                          17
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[17]?.["18"]
                          )}`}
                        >
                          18
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[18]?.["19"]
                          )}`}
                        >
                          19
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[19]?.["20"]
                          )}`}
                        >
                          20
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[20]?.["21"]
                          )}`}
                        >
                          21
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[21]?.["22"]
                          )}`}
                        >
                          22
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[22]?.["23"]
                          )}`}
                        >
                          23
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[23]?.["24"]
                          )}`}
                        >
                          24
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[24]?.["25"]
                          )}`}
                        >
                          25
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[25]?.["26"]
                          )}`}
                        >
                          26
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[26]?.["27"]
                          )}`}
                        >
                          27
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[27]?.["28"]
                          )}`}
                        >
                          28
                        </td>
                      </tr>
                      <tr>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[28]?.["29"]
                          )}`}
                        >
                          29
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[29]?.["30"]
                          )}`}
                        >
                          30
                        </td>
                        <td
                          className={`min-w-[30px] text-sm lg:text-base text-center border border-black lg:min-w-0 lg:py-2  ${colorFunc(
                            poi.days[30]?.["31"]
                          )}`}
                        >
                          31
                        </td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  <tbody className="bg-white">
                    <tr>
                      <td colSpan="15" className="text-center py-4 text-gray">
                        <div className="flex flex-col justify-center items-center mt-4">
                          <BsDatabaseSlash size={30} className="mb-2" />
                          <p>Pas de données disponibles.</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
