import React from 'react'
import { Navigate } from 'react-router-dom';

export const ProtectedRoutes = ({ children }) => {
    // Replace the following line with your actual authentication logic
    const isAuthenticated = localStorage.getItem('isAuth');

    if (!isAuthenticated) {
        // Redirect to the login page if not authenticated
        return <Navigate to="/" />;
    }

    // Render the protected content if authenticated
    return <>{children}</>;
}
