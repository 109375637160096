import React, { useState } from 'react';
// Packages :
import { Skeleton } from '@mui/material';
// Icons :
import { CiSearch } from "react-icons/ci";
import { BiExport } from "react-icons/bi";
import { BsDatabaseSlash } from "react-icons/bs";
import { IoIosCheckmark } from 'react-icons/io';
// Date Picker :
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
// Toast :
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Firebase :
import { getDocs, query, where } from 'firebase/firestore';
import { affaireCollection, pointageCollection } from '../config/firebaseConfig';
// Sidebar :
import Sidebar from '../components/Sidebar';

const Historique = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    // CSV Data : 
    const [time, setTime] = useState([]);
    const [affaires, setAffaires] = useState([]);
    const [extraHr, setExtraHr] = useState([]);
    const [normalHr, setNormaHr] = useState([]);
    const [messages, setMessages] = useState([]);
    const [deplacement, setDeplacement] = useState([]);
    const [transport, setTranssport] = useState([]);
    const [holiday, setHoliday] = useState([]);

    // ? ===================|| Get All Verified Pointage ||===================
    const handleHistorique = async () => {
        const id = localStorage.getItem("id");
        const group_id = localStorage.getItem("group_id");
        const data = [];

        try {
            setLoading(true);
            const affairesSnapshot = await getDocs(affaireCollection);
            const affaires = affairesSnapshot.docs.map((doc) => doc.data());

            const pointageQuery = query(
                pointageCollection,
                where('user_id', '==', id),
                where('date', '>=', startDate),
                where('date', '<=', endDate),
                where("verified", "==", true),
            );
            const pointageSnapshot = await getDocs(pointageQuery);
            const pointage = pointageSnapshot.docs.map((doc) => doc.data());

            pointage.forEach((poi) => {
                const projectsNormalWork = poi.normalWork.map((work) => {
                    const affaire = affaires.find((aff) => aff.codeAffaire === work.codeAffaire);
                    return {
                        affaireName: affaire ? affaire.nameAffaire : "-",
                        hours: work.hours,
                    };
                });

                const projectsExtraWork = poi.extraWork.map((work) => {
                    const affaire = affaires.find((aff) => aff.codeAffaire === work.codeAffaire);
                    return {
                        affaireName: affaire ? affaire.nameAffaire : "-",
                        hours: work.hours,
                    };
                });

                let pointageSummaryMessage = '';

                projectsNormalWork.forEach((project) => {
                    pointageSummaryMessage += `${project.affaireName} / `;
                });
                projectsExtraWork.forEach((project) => {
                    pointageSummaryMessage += `${project.affaireName} / `;
                });

                // Remove the slash from the last sentence :
                const updatedPointageSummaryMessage = pointageSummaryMessage.slice(0, -2);

                let his = {
                    projectsNormalWork,
                    projectsExtraWork,
                    totalNormalwork: poi.totalNormalwork,
                    totalExtrawork: poi.totalExtrawork,
                    date: formatTimestamp(poi.date),
                    affaires: updatedPointageSummaryMessage,
                    transport: poi.transport,
                    transportPrice: poi.transportPrice,
                    deplacement: poi.deplacement,
                    deplacementPrice: poi.deplacementPrice,
                    message: poi.message,
                    holiiday: poi.holiday,
                    id: poi.pointage_id,
                    isSunday: poi.isSunday,
                    conge: poi.conge,
                    absent: poi.absent,
                    malade: poi.malade,
                    recuperations: poi.recuperations
                };

                data.push(his);
            });

            setData(data);
            dataFormat(data);
            setLoading(false);
        } catch (error) {
            console.error('Error in timeHandler:', error);
            return false;
        }
    };
    function formatTimestamp(timestamp) {
        const dateObj = timestamp.toDate();
        const month = dateObj.getMonth() + 1;
        const day = dateObj.getDate();
        const year = dateObj.getFullYear();

        return `${day}/${month}/${year}`;
    };
    function isSunday(dateString) {
        // Parse the date string in the format "DD/MM/YYYY"
        const [day, month, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript

        // Sunday is represented by 0 in JavaScript
        return date.getDay() === 0;
    };
    // ? ======================================================================

    // ? ========================|| Start & End Date ||========================
    const currentDate = new Date();
    const maxEndDate = new Date();
    maxEndDate.setDate(currentDate.getDate() - 60);
    const tomorrow = new Date();
    tomorrow.setDate(currentDate.getDate() + 1);
    // ? ======================================================================

    // ? ============================|| Tostify ||=============================
    const notifyInfo = () => toast.info('Pas de données disponibles.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    // ? ======================================================================

    // ? ========================|| Export to CSV ||===========================
    const dataFormat = (d) => {
        let timeCsv = [];
        let affairesCsv = [];
        let extraHrCsv = [];
        let normalHrCsv = [];
        let messageCsv = [];
        let deplacementeCsv = [];
        let transportCsv = [];
        let holidayCsv = [];

        for (let n = 0; n < d.length; n++) {
            timeCsv.push(d[n].date);
            affairesCsv.push(d[n].affaires)
            extraHrCsv.push(d[n].totalExtrawork);
            normalHrCsv.push(d[n].totalNormalwork);
            messageCsv.push(d[n].message);
            deplacementeCsv.push(d[n].deplacementPrice);
            transportCsv.push(d[n].transportPrice);
            holidayCsv.push(d[n].holiday);
        };

        setTime(timeCsv);
        setAffaires(affairesCsv);
        setExtraHr(extraHrCsv);
        setNormaHr(normalHrCsv);
        setMessages(messageCsv);
        setDeplacement(deplacementeCsv);
        setTranssport(transportCsv);
        setHoliday(holidayCsv);
    };
    const dataExport = () => {
        let result = `Time, Affaires, Normal Heures, Heures supplementaires, Message, Deplacement, Transport \n`;
        const dataLenght = data.length;
        for (let i = 0; i < dataLenght; i++) {
            result += `${time[i]}, ${affaires[i]}, ${normalHr[i]}, ${extraHr[i]}, ${messages[i]}, ${deplacement[i]}, ${transport[i]},\n`;
        }
        return result;
    };
    async function exportToExcel() {
        if (data.length > 0) {
            const fileData = await dataExport();
            const blob = new Blob([fileData], { type: "text/plain" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");

            const date = new Date();

            // Format the date as 'MM/DD/YYYY'
            const formattedDate = date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }).replace(/\//g, '-'); // Replace '/' with '-' for filename compatibility

            link.download = `PTrack-PPrime_${formattedDate}.csv`;
            link.href = url;
            link.click();
        } else {
            notifyInfo();
        }
    };
    // ? ======================================================================

    return (
        <div className="flex lg:gap-5 md:gap-3 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                />

                <p className='text-2xl md:text-3xl lg:text-4xl font-bold mb-4'>Historique</p>

                {/* Start Date & End Date & Button */}
                <div className='lg:flex justify-between items-end'>
                    {/* Starte & End Date */}
                    <div className='lg:flex w-full lg:w-2/3'>
                        {/* Start Date */}
                        <div className='w-full lg:w-1/3 mt-4 lg:mt-0 md:mr-2'>
                            <p>Date de début</p>
                            <DatePicker
                                wrapperClassName="datePicker"
                                className='w-full border border-gray rounded-md px-4 py-2'
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                minDate={maxEndDate}
                                maxDate={currentDate}
                                placeholderText='Sélectionnez la date de début'
                            />
                        </div>

                        {/* End Date */}
                        <div className='w-full lg:w-1/3 mt-4 lg:mt-0'>
                            <p>Date de fin</p>
                            <DatePicker
                                wrapperClassName="datePicker"
                                className='w-full border border-gray rounded-md px-4 py-2'
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                minDate={maxEndDate}
                                maxDate={tomorrow}
                                placeholderText='Sélectionnez la date de fin'
                            />
                        </div>
                    </div>

                    {/* Search & Export Button */}
                    <div className='flex w-full lg:w-1/3 justify-end mt-4 lg:mt-0'>
                        {/* Search Button */}
                        <div className='mr-4'>
                            <button
                                className='flex items-center bg-primary text-white font-medium px-4 py-2 rounded-md'
                                onClick={() => handleHistorique()}
                            >
                                <CiSearch size={20} strokeWidth={1.5} className='mr-4' />
                                Search
                            </button>
                        </div>
                        {/* Export Button */}
                        <div>
                            <button
                                className='flex items-center bg-primary text-white font-medium px-4 py-2 rounded-md'
                                onClick={() => exportToExcel()}
                            >
                                <BiExport size={20} className='mr-4' />
                                Exporter
                            </button>
                        </div>
                    </div>
                </div>

                {/* Table */}
                <div className="my-4 flex flex-col flex-wrap lg:flex-row lg:justify-start lg:items-center">
                    <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
                        <div className="w-6 h-6 bg-gray rounded-full border border-black"></div>
                        <span className="text-sm text-black ml-2 pt-1">
                            Dimanche
                        </span>
                    </div>

                    <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
                        <div className="w-6 h-6 bg-lightBlue rounded-full border border-black"></div>
                        <span className="text-sm text-black ml-2 pt-1">
                            Récupérations
                        </span>
                    </div>

                    <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
                        <div className="w-6 h-6 bg-purple rounded-full border border-black"></div>
                        <span className="text-sm text-black ml-2 pt-1">Congé</span>
                    </div>

                    <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
                        <div className="w-6 h-6 bg-blue rounded-full border border-black"></div>
                        <span className="text-sm text-black ml-2 pt-1">Malade</span>
                    </div>

                    <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
                        <div className="w-6 h-6 bg-softRed rounded-full border border-black"></div>
                        <span className="text-sm text-black ml-2 pt-1">Absent(e)</span>
                    </div>
                </div>

                <div className="overflow-x-scroll">
                    <table className='w-full overflow-hidden rounded-t-lg bg-white'>
                        <thead className="bg-primary text-white py-2">
                            <tr>
                                <th
                                    className="py-3 px-3 text-sm lg:text-base border border-white"
                                >
                                    Date
                                </th>
                                <th
                                    className="py-3 px-3 text-sm lg:text-base border border-white"
                                >
                                    Affaires
                                </th>
                                <th
                                    className="py-3 px-3 text-sm lg:text-base border border-white"
                                >
                                    Normal Heures
                                </th>
                                <th
                                    className="py-3 px-3 text-sm lg:text-base border border-white"
                                >
                                    Heures <br />
                                    supplémentaire
                                </th>
                                <th
                                    className="py-3 px-3 text-sm lg:text-base border border-white"
                                >
                                    Message
                                </th>
                                <th
                                    className="py-3 px-3 text-sm lg:text-base border border-white"
                                >
                                    Transport
                                </th>
                                <th
                                    className="py-3 px-3 text-sm lg:text-base border border-white"
                                >
                                    Déplacement
                                </th>
                                <th
                                    className="py-3 px-3 text-sm lg:text-base border border-white"
                                >
                                    Jour férié
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {loading ? (
                                <tr>
                                    <td
                                        colSpan={9}
                                    >
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                    </td>
                                </tr>
                            ) :
                                data && data.length > 0 ? (
                                    data?.map((data, index) => (
                                        <tr key={index}
                                            className={`
                                            ${isSunday(data?.date) === true ? "border-2 border-black bg-lightgray" : ""} 
                                            ${data?.absent === true ? 'bg-softRed text-white' : ''}
                                            ${data?.recuperations === true ? 'bg-lightBlue text-white' : ''}
                                            ${data?.conge === true ? 'bg-purple text-white' : ''}
                                            ${data?.malade === true ? 'bg-purple text-white' : ''}
                                        `}
                                        >
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {data?.date}
                                            </td>
                                            <td
                                                className={`${data?.affaires === "-" ? "bg-light border-x-0" : ""} border border-gray py-2 px-3 text-sm lg:text-base`}
                                                dangerouslySetInnerHTML={{ __html: data?.affaires }}
                                            ></td>
                                            <td
                                                className={`${data?.totalNormalwork === "-" ? "bg-light border-x-0" : ""
                                                    } border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {data?.totalNormalwork}
                                            </td>
                                            <td
                                                className={`${data?.totalExtrawork === "-" ? "bg-light border-x-0" : ""
                                                    } border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {data?.totalExtrawork}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {data?.message ? data?.message : '-'}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {data?.transport && data?.transportPrice !== '' ? data?.transportPrice + ' DHs' : null}
                                                <p className="w-full flex justify-center">
                                                    {data?.transport && data?.transportPrice === '' ? <IoIosCheckmark color="green" size={34} /> : null}
                                                </p>
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {data?.deplacement && data?.deplacementPrice !== '' ? data?.deplacementPrice + ' DHs' : null}
                                                <p className="w-full flex justify-center">
                                                    {data?.deplacement && data?.deplacementPrice === '' ? <IoIosCheckmark color="green" size={34} /> : null}
                                                </p>
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                <p className="w-full flex justify-center">
                                                    {data?.holiday ? <IoIosCheckmark color="green" size={34} /> : null}
                                                </p>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center py-4 text-gray">
                                            <div className='flex flex-col justify-center items-center mt-4'>
                                                <BsDatabaseSlash size={30} className='mb-2' />
                                                <p>Pas de données disponibles.</p>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

            </main>
        </div>
    )
}

export default Historique;