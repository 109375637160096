import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// Firebase :
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, userCollection } from "../config/firebaseConfig";
import { getDocs } from "firebase/firestore";
// Images :
import Logo from "../assets/img/Logo+slogan.png";
import Loading from "../assets/img/white-loading.gif";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  let id;
  let user;

  const loginToFirebase = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email.trim(),
        password
      );
      user = userCredential.user;
      id = user.uid;
      localStorage.setItem("id", id);

      try {
        setIsLoading(true);
        const userSnapshot = await getDocs(userCollection);
        const user = userSnapshot.docs.find((doc) => doc.id === id);
        const userData = user.data();

        localStorage.setItem("group_id", userData.group_id);

        if (user.data().role === "Agent") {
          // ================================================================
          setIsLoading(false);
          setError("");
          // ================================================================
          localStorage.setItem("isAuth", true);
          return navigate("/dashboard");
        } else {
          setError("Vous n'avez pas accès");
          setIsLoading(false);
        }
      } catch (error) {}
    } catch (error) {
      setError("E-mail ou mot de passe incorrect");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      loginToFirebase();
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      {isLoading ? (
        <div className="bg-black/30 z-20 w-screen h-screen fixed flex justify-center items-center">
          <img src={Loading} alt="" className="w-32" />
        </div>
      ) : null}

      <div className="w-[90%] lg:w-[35%] mx-auto bg-white rounded-xl px-4 lg:px-6 py-6 lg:py-10 border border-gray">
        {/* Logo */}
        <div className="w-full flex justify-center mb-16">
          <img src={Logo} alt="pprime" className="w-[90%]" />
        </div>

        {/* Error */}
        {error && (
          <p className="bg-red/90 py-2 rounded-md text-white font-bold text-center mb-4">
            {error}
          </p>
        )}

        {/* Email */}
        <div className="w-full mb-4">
          <p className="mb-1">E-mail</p>
          <input
            type="text"
            placeholder="Entrez votre e-mail"
            className="w-full border border-gray py-2 px-4 rounded-md"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>

        {/* Password */}
        <div className="w-full mb-6">
          <p className="mb-1">Mot de passe</p>
          <input
            type="password"
            placeholder="********"
            className="w-full border border-gray py-2 px-4 rounded-md"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>

        {/* Connect Button */}
        <div className="w-full flex justify-end">
          <button
            className="px-4 py-2 bg-primary text-white font-medium rounded-md"
            onClick={() => loginToFirebase()}
          >
            Se Connecter
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
