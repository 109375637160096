import React, { useEffect, useState } from 'react';
// Images :
import User from '../assets/img/logo.png';
// Sidebar :
import Sidebar from '../components/Sidebar';
// Components :
import ChangePasswordModal from '../components/ChangePasswordModal';
import { getDocs, query, where } from 'firebase/firestore';
import { affaireCollection, pointageCollection, userCollection } from '../config/firebaseConfig';
// Toast :
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profil = () => {
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [data, setData] = useState([]);

    // ? =================|| User Information ||=================
    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const id = localStorage.getItem('id');
                const userSnapshot = await getDocs(userCollection);
                const user = userSnapshot.docs.find((doc) => doc.id === id);
                const data = user.data();

                setName(data.fullName);
                setRole(data.role);
            } catch (error) { }
        }

        const MonthlyWorkedHours = async () => {
            const id = localStorage.getItem('id');
            const group = localStorage.getItem('group_id');
            const todayDate = new Date();

            // Calculate the first day of the current month
            const firstDayOfMonth = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);

            try {
                // Fetch  affaires that have the same group id
                const affairesSnapshot = await getDocs(query(affaireCollection,
                    where("group_id", "==", group),
                ));
                const affaires = affairesSnapshot.docs.map((doc) => doc.data());

                // Query the Firestore collection to get all documents with the specified agent ID and date range
                const querySnapshot = await getDocs(query(pointageCollection,
                    where("agaent_id", "==", id),
                    where("date", ">=", firstDayOfMonth),
                    where("date", "<=", todayDate),
                    // where("verified", "==", true)
                ));
                const pointageData = querySnapshot.docs.map((doc) => doc.data());

                const data = {
                    Works: [],
                    totalHours: 0,
                };

                // Organize the work data by codeAffaire and calculate total hours
                const codeAffaireMap = new Map();

                pointageData.forEach((work) => {
                    const { normalWork, extraWork } = work;

                    // Handle Normal Work
                    Object.values(normalWork).forEach((period) => {
                        const { codeAffaire, hours } = period;
                        if (!codeAffaireMap.has(codeAffaire)) {
                            codeAffaireMap.set(codeAffaire, { codeAffaire, nameAffaire: "", totalHours: 0 });
                        }
                        codeAffaireMap.get(codeAffaire).totalHours += hours;
                    });

                    // Handle Extra Work
                    if (extraWork) {
                        Object.values(extraWork).forEach((period) => {
                            const { codeAffaire, hours } = period;
                            if (!codeAffaireMap.has(codeAffaire)) {
                                codeAffaireMap.set(codeAffaire, { codeAffaire, nameAffaire: "", totalHours: 0 });
                            }
                            codeAffaireMap.get(codeAffaire).totalHours += hours;
                        });
                    }
                });

                // Retrieve and add Affaire names, filter out entries with empty codeAffaire or nameAffaire
                for (const [code, value] of codeAffaireMap.entries()) {
                    const matchingAffaire = affaires.find(affaire => affaire.codeAffaire === code);
                    if (matchingAffaire) {
                        value.nameAffaire = matchingAffaire.nameAffaire;
                    }

                    // Add to Works array only if both codeAffaire and nameAffaire are non-empty
                    if (value.codeAffaire && value.nameAffaire) {
                        data.Works.push({
                            codeAffaire: value.codeAffaire,
                            nameAffaire: value.nameAffaire,
                            hours: value.totalHours,
                        });
                    }
                }

                data.totalHours = data.Works.reduce((total, work) => total + work.hours, 0);

                // res.json(data);
                setData(data.Works);
            } catch (error) { }
        };

        getUserInfo();
        MonthlyWorkedHours();
    }, []);

    return (
        <div className="flex lg:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                />

                <p className='text-2xl md:text-3xl lg:text-4xl font-bold mb-4'>Profil</p>

                <div className='flex flex-wrap justify-between items-center mt-10 bg-white shadow-gray shadow-sm rounded-lg p-4'>
                    {/* Picture & Name & Role */}
                    <div className="flex items-center">
                        <img src={User} alt="" className='w-[80px] h-[80px] rounded-full mr-4 border border-lightgray' />
                        <div>
                            <p className='text-xl lg:text-2xl font-bold'>{name.toLocaleUpperCase()}</p>
                            <p>Agent</p>
                        </div>
                    </div>

                    <ChangePasswordModal />
                </div>
            </main>
        </div>
    )
}

export default Profil;