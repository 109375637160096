import { getDocs, query, where } from "firebase/firestore";
import { affaireCollection } from "./firebaseConfig";

export const getAllAffaires = async () => {
    try {

        const querySnapshot = await getDocs(affaireCollection);
        const affaires = querySnapshot.docs.map((doc) => {
            const { nameAffaire, codeAffaire } = doc.data();
            return { label: `${codeAffaire} - ${nameAffaire}`, value: codeAffaire };
        });


        return affaires;
    } catch (error) { }
}